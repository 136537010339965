import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './assets/css/style.css'
import {getRequest} from "@/api/api";
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import { Button } from 'element-ui'
import 'element-ui/lib/theme-chalk/base.css'
import 'element-ui/lib/theme-chalk/button.css'
import 'element-ui/lib/theme-chalk/icon.css'

import BaiduMap from 'vue-baidu-map';
import {BmlMarkerClusterer} from 'vue-baidu-map';

import store from "./store"

Vue.component('bml-marker-cluster', BmlMarkerClusterer);
Vue.use(BaiduMap, {
  ak: 'q1U1jaXOS32iggmHlELB5hiI515ipXcL' // 百度地图秘钥
})

Vue.config.productionTip = false;
Vue.prototype.getRequest = getRequest;


Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.use(Button)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
