import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        selectedLanguage: null // 初始值为null
    },
    mutations: {
        setSelectedLanguage(state, languageId) {
            state.selectedLanguage = languageId;
        }
    },
    actions: {
        fetchLanguages({ commit }) {
            fetch('prod-api/trademanager/language/list')
                .then(response => response.json())
                .then(data => {
                    commit('setSelectedLanguage', data.rows[0].id); // 假设默认语言是第一个
                })
                .catch(error => {
                    console.error('Error fetching languages:', error);
                });
        }
    }
});
